<template>
  <div
    class="mb-3 mt-4"
    style="
      display: grid;
      row-gap: 1rem;
      column-gap: 2rem;
      grid-template-columns: repeat(5, minmax(min-content, auto));
      max-width: 680px;
    "
  >
    <div
      v-for="(file, i) in files"
      :key="file.name"
      style="display: contents"
      class="file-row"
    >
      <i
        v-if="fileTypeIcon(file.type, null)"
        :class="`fa ${fileTypeIcon(file.type)}`"
      />
      <img
        v-else-if="file.thumbnail"
        :src="file.thumbnail"
        :alt="file.name"
        class="mr-auto"
        style="object-fit: cover; width: 147px; height: 81px"
      />
      <i v-else class="fa fa-file-o" />

      <div style="max-width: 250px">
        <p
          class="font-weight-bold"
          :class="{ invisible: i !== 0 }"
          style="color: #5e5e5e"
        >
          File name
        </p>
        <p
          class="mb-0 text-muted"
          style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap"
          :title="file.name"
        >
          {{ file.name }}
        </p>
      </div>
      <div style="width: 75px">
        <p
          class="font-weight-bold"
          :class="{ invisible: i !== 0 }"
          style="color: #5e5e5e"
        >
          Size
        </p>
        <p class="mb-0 text-muted">{{ humanizeBytes(file.size) }}</p>
      </div>
      <div style="width: 78px">
        <p
          class="font-weight-bold"
          :class="{ invisible: i !== 0 }"
          style="color: #5e5e5e"
        >
          Date
        </p>
        <p class="mb-0 text-muted">
          {{
            toWorkspaceDateTimeFormat(getLuxonDateTime(file.created_on, false))
          }}
        </p>
      </div>

      <div
        class="ml-auto d-flex justify-content-center align-items-center gap-x-2"
      >
        <button
          v-if="editable && $auth.user.isAgent"
          class="btn btn-danger"
          @click="$emit('removeFile', i)"
        >
          <i class="icon-trash"></i>
        </button>
        <a :href="file.url" target="_blank" class="btn btn-primary">
          <i class="icon-cloud-download"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import timeMixin from "../../../mixins/time";
import xbytes from "xbytes";
import { fileTypeIcon } from "@/utils/icon";

export default {
  name: "RevisionFiles",
  mixins: [timeMixin],
  props: {
    files: Array,
    editable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    fileTypeIcon,
    humanizeBytes(bytes) {
      if (!bytes) return "";

      return xbytes(bytes);
    },
  },
};
</script>

<style scoped>
.btn-warning {
  background-color: #e57671;
  border-color: #e57671;
  color: white;
}

.btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
}

.file-row > i {
  font-size: 1.75rem;
  align-self: center;
  place-self: center;
}
</style>
