<template>
  <wrapper-page>
    <template v-slot:MainContentHeaderActions>
      <div
        :class="{
          'col-md-8 col-sm-12 text-right hidden-xs': true,
        }"
      >
        <button
          v-on:click="submitForm"
          class="btn btn-primary"
          :disabled="
            isSavingDetails ||
            isSubscriptionReadOnly() ||
            isProjectCancelled ||
            isArchived
          "
        >
          {{ isCreating ? "Create Project" : "Save Changes" }}
        </button>
        <router-link
          v-if="!isCreating && project.deliverable_type === 'Multi'"
          :to="{ path: `/projects/${projectId}/timelines` }"
          class="btn btn-primary ml-2"
        >
          View timelines
        </router-link>
        <router-link
          v-else-if="!isCreating && project.deliverable_type === 'Single'"
          :to="{ path: `/projects/${projectId}/timeline` }"
          class="btn btn-primary ml-2"
        >
          View timeline
        </router-link>
        <div class="dropdown show" style="display: inline">
          <a
            class="menuLink ml-2"
            href="javascript:void(0);"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            ><img
              src="/assets/icons/dots-three-circle.svg"
              height="30px"
              width="30px"
              style="margin-top: -1px"
              alt="menu"
          /></a>
          <ul
            :class="{
              'dropdown-menu dropdown-menu-right': true,
              disabledComponent: isProjectCancelled,
            }"
          >
            <li
              v-if="!isCreating && !isLoadingDetails"
              @click="isProjectArchiveOrUnarchiveClick = true"
            >
              <a href="javascript:void(0);">{{ archiveButtonText }}</a>
            </li>
            <li
              v-if="!isCreating && !isLoadingDetails && !project.archived"
              @click="isProjectCancelModalOpen = true"
            >
              <a href="javascript:void(0);">Cancel Project</a>
            </li>
            <li
              v-if="!isCreating && !isLoadingDetails && project.archived"
              @click="deleteProject"
            >
              <a href="javascript:void(0);">Delete Project</a>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <div
      :class="{
        disabledComponent: isProjectCancelled || isArchived,
      }"
    >
      <template v-if="!isLoadingDetails && project">
        <div class="row clearfix">
          <div class="col-lg-12 col-md-12">
            <div class="card">
              <div class="header">
                <h2>{{ isCreating ? "New Project" : this.project.name }}</h2>
              </div>
              <div class="body">
                <form
                  ref="projectForm"
                  @submit.prevent="submitProject"
                  :disabled="isSubscriptionReadOnly()"
                >
                  <div class="row clearfix">
                    <div
                      class="col-lg-6 col-md-6"
                      v-for="model in mainDetails.slice(0, 3)"
                      :key="model.name || model.label"
                    >
                      <form-input-group
                        v-if="model.name === 'client_id'"
                        v-model="project[model.name]"
                        :field="model"
                        @input="clientSelected"
                      />
                      <form-input-group
                        v-else
                        :field="model"
                        v-model="project[model.name]"
                        :required="model.name == 'name'"
                      />
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <multi-select-dropdown
                        ref="ms_TeamMembers"
                        :field="{
                          label: 'Team Members',
                          options: userOptions,
                        }"
                        :value="users"
                        @input="updateTeamMembers"
                      ></multi-select-dropdown>
                    </div>
                  </div>

                  <!-- Start date and dates -->
                  <div class="row clearfix">
                    <div class="col-lg-3 col-md-3">
                      <form-input-group
                        :field="mainDetails[3]"
                        v-model="project[mainDetails[3].name]"
                      />
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <b>
                        <label id="final-deliverable">
                          Final Deliverable
                        </label>
                      </b>
                      <br />
                      <div class="btn-group mr-2">
                        <button
                          type="button"
                          :class="{
                            btn: true,
                            'btn-default': !isSingleDeliverableType,
                            'btn-primary': isSingleDeliverableType,
                          }"
                          class="btn-round"
                          @click="toggleMode"
                        >
                          Single
                        </button>
                        <button
                          type="button"
                          :class="{
                            btn: true,
                            'btn-default': isSingleDeliverableType,
                            'btn-primary': !isSingleDeliverableType,
                          }"
                          class="btn-round"
                          @click="toggleMode"
                        >
                          Multi
                        </button>
                      </div>
                    </div>
                    <div class="col-lg-2">
                      <b
                        ><label
                          for="input-days_allowed_for_client_feedback"
                          id="feedback-timeframe"
                          >Feedback timeframe</label
                        ></b
                      >
                      <div class="input-group mb-3">
                        <input
                          type="number"
                          id="input-days_allowed_for_client_feedback"
                          class="form-control"
                          style="min-width: 5em; max-width: 5em"
                          aria-label="days allowed for client feedback"
                          v-model="project.days_allowed_for_client_feedback"
                          aria-describedby="basic-addon1"
                          required
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text"
                            style="border-width: 0"
                            id="basic-addon2"
                            >business days</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-2">
                      <b
                        ><label for="checkbox-reminder" id="automated-reminder"
                          >Automated reminder</label
                        ></b
                      >
                      <div class="input-group mb-3 d-flex gap-x-2">
                        <label class="switch" @click="handleCheckboxClick">
                          <input
                            id="checkbox-reminder"
                            type="checkbox"
                            v-model="project.auto_reminder"
                            :disabled="
                              !isProfessionalLevelUser || !canAutoReminderOn
                            "
                          />
                          <span class="slider round"></span>
                        </label>
                        <i
                          v-if="!isProfessionalLevelUser"
                          @click="handleCheckboxClick"
                          class="fa fa-lock"
                          style="margin-top: 4px"
                        ></i>
                      </div>
                    </div>
                    <div v-if="project.auto_reminder" class="col-lg-2">
                      <b
                        ><label for="ddlTemplates" id="reminder-frequency"
                          >Reminder frequency</label
                        ></b
                      >
                      <div class="input-group mb-3">
                        <button
                          id="ddlTemplates"
                          class="btn btn-outline-secondary dropdown-toggle"
                          type="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {{ reminderIntervalText }}
                        </button>
                        <div class="dropdown-menu">
                          <a
                            v-for="(interval, index) in reminderIntervals"
                            :key="index"
                            class="dropdown-item"
                            @click="onIntervalSelected(interval)"
                            >{{ interval?.text }}</a
                          >
                        </div>
                      </div>
                    </div>

                    <!-- <div class="col-lg-3 col-md-3">
                <b><label for="input-feedback_reminder_delay">Feedback reminder delay</label></b><span class="text-muted"> - coming soon</span>
                <div class="input-group mb-3">
                  <! -- <div class="input-group-prepend">
                      <span class="input-group-text">Feedback reminder delay</span>
                  </div> -- >
                  <input type="number" id="input-feedback_reminder_delay" class="form-control" aria-label="" v-model="project.feedback_reminder_delay" aria-describedby="basic-addon1">
                  <div class="input-group-append">
                      <span class="input-group-text" style="border-width:0" id="basic-addon2">business days after time allowed</span>
                  </div>
                </div>
              </div> -->
                  </div>

                  <!-- Subject line & Team Members-->
                  <div class="row clearfix">
                    <div class="col-lg-6 col-md-6">
                      <form-input-group
                        :field="mainDetails[4]"
                        v-model="project.email_subject_line_default"
                      />
                    </div>
                    <div
                      v-if="isSingleDeliverableType"
                      class="col-lg-3 col-md-3"
                    >
                      <b
                        ><label id="timeline-structure"
                          >Timeline structure</label
                        ></b
                      ><br />
                      <div class="btn-group mr-2">
                        <button
                          type="button"
                          :class="{
                            btn: true,
                            'btn-default': !isLinear,
                            'btn-primary': isLinear,
                          }"
                          class="btn-round"
                          @click="toggleTimelineStructure"
                        >
                          Linear
                        </button>
                        <button
                          type="button"
                          :class="{
                            btn: true,
                            'btn-default': isLinear,
                            'btn-primary': !isLinear,
                          }"
                          class="btn-round"
                          @click="toggleTimelineStructure"
                        >
                          Non-linear
                        </button>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <b><label for="checkbox-skip-csat">CSAT survey</label></b>
                      <div class="input-group mb-3 d-flex gap-x-2">
                        <label class="switch">
                          <input
                            id="checkbox-skip-csat"
                            type="checkbox"
                            v-model="project.send_csat_survey"
                            :disabled="
                              !isProfessionalLevelUser || !$auth.user.owner
                            "
                          />
                          <span class="slider round"></span>
                        </label>
                        <i
                          v-if="!isProfessionalLevelUser"
                          @click="handleCSATCheckboxClick"
                          class="fa fa-lock"
                          style="margin-top: 4px"
                        ></i>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <contact-list-editor
          ref="contactsList"
          :contacts="contacts"
          :contactOptions="contactOptions"
          title="Client Contacts"
          :with-existing-contacts="true"
          @contactSelected="(id) => existingContactAdded(id, false)"
          @contactAdded="(id) => existingContactAdded(id, true)"
        />
      </template>

      <workflow-editor
        ref="workflowEditor"
        v-if="workflow && isSingleDeliverableType"
        :workflow="workflow"
        :templates="templates"
        @wfChange="workflow.tasks = $event.tasks"
      ></workflow-editor>

      <multi-workflow-editor
        ref="multiWorkflowEditor"
        v-if="isShowMultiWorkflowEditor"
        :workflow="workflow"
        :multiDeliverables="multiDeliverables"
        :templates="templates"
        @wfChange="onWfChange"
        @delete="(workflow_id) => deletedDeliverables.push(workflow_id)"
      ></multi-workflow-editor>
    </div>

    <div class="row clearfix pb-5">
      <div class="col-lg-6 col-md-6">
        <button
          v-on:click="submitForm"
          class="float-right btn btn-primary"
          :disabled="
            isSavingDetails ||
            isSubscriptionReadOnly() ||
            isProjectCancelled ||
            isArchived
          "
        >
          {{ isCreating ? "Create Project" : "Save Changes" }}
        </button>
      </div>
      <div v-if="!isCreating && !isLoadingDetails" class="col-lg-6 col-md-6">
        <button
          v-on:click="deleteProject"
          class="float-right btn btn-danger"
          :disabled="isSubscriptionReadOnly()"
        >
          Delete
        </button>
      </div>
    </div>
    <ActionConfirmModal
      v-if="isProjectArchiveOrUnarchiveClick"
      :title="getProjectArchiveModalProps.title"
      :text="getProjectArchiveModalProps.text"
      :submitText="getProjectArchiveModalProps.submitText"
      :submitClass="getProjectArchiveModalProps.submitClass"
      :isShowSubmitButton="getProjectArchiveModalProps.isShowSubmitButton"
      @close="isProjectArchiveOrUnarchiveClick = false"
      @submit="
        () => {
          if (isSubscriptionReadOnly()) return;

          toggleArchive();
          isProjectArchiveOrUnarchiveClick = false;
        }
      "
    />
    <WarningOrErrorModal
      v-if="isShowWarningForLinearSwitch"
      :title="linearSwitchWarningModalProps.title"
      :text="linearSwitchWarningModalProps.text"
      @close="isShowWarningForLinearSwitch = false"
    />
    <ActionConfirmModal
      v-if="isProjectCancelModalOpen"
      :title="projectCancelModalProps.title"
      :text="projectCancelModalProps.text"
      :submitText="projectCancelModalProps.submitText"
      @close="isProjectCancelModalOpen = false"
      @submit="
        () => {
          if (isSubscriptionReadOnly()) return;

          cancelProject();
          isProjectCancelModalOpen = false;
        }
      "
    />
    <ActionConfirmModal
      v-if="isShowErrorForAutomatedReminder"
      :title="automatedReminderErrorModelProps.title"
      :text="automatedReminderErrorModelProps.text"
      :submitText="automatedReminderErrorModelProps.submitText"
      @close="isShowErrorForAutomatedReminder = false"
      submitClass="btn-primary"
      @submit="
        () => {
          isShowErrorForAutomatedReminder = false;
          navigateToEmailTemplate();
        }
      "
    />
    <ChooseTimelineStructureModal
      v-if="showChooseTimelineStructureModal"
      :templates="templates"
      @close="showChooseTimelineStructureModal = false"
      @submit="onTimeLineStructureSelected"
    />
    <ChooseProjectDeliverableTypeModal
      v-if="showChooseProjectDeliverableTypeModal"
      @close="showChooseProjectDeliverableTypeModal = false"
      @submit="onProjectDeliverableSelected"
    />
  </wrapper-page>
</template>

<script>
import timeMixin from "../mixins/time";
import {
  getProjectById,
  addProject,
  updateProject,
  archiveProjectById,
  deleteProjectById,
  cancelProjectById,
} from "../apis/projects";
import {
  addWorkflow,
  getWorkflowById,
  updateWorkflow,
} from "../apis/workflows";
import { getWorkflowTemplates } from "../apis/workflow-templates";
import WrapperPage from "../components/layout/WrapperPage.vue";
import FormInputGroup from "../components/ui/FormInputGroup.vue";
import WorkflowEditor from "../components/ui/WorkflowEditor.vue";
import MultiWorkflowEditor from "../components/ui/MultiWorkflowEditor.vue";
import ContactListEditor from "../components/ui/ContactListEditor.vue";
import clientsMixin from "../mixins/clients";
import workflowMixin from "../mixins/workflow";
import MultiSelectDropdown from "../components/ui/MultiSelectDropdown.vue";
import { getClientById } from "@/apis/clients";
import { associateContactToClient } from "@/apis/contacts";
import ActionConfirmModal from "../components/ui/Modals/ActionConfirmModal.vue";
import WarningOrErrorModal from "../components/ui/Modals/WarningOrErrorModal.vue";
import ChooseTimelineStructureModal from "../components/ui/Modals/ChooseTimelineStructureModal.vue";
import ChooseProjectDeliverableTypeModal from "../components/ui/Modals/ChooseProjectDeliverableTypeModal.vue";
import { DateTime } from "luxon";
import { isWorkspaceProfessionalLevel } from "@/utils/helper";
import eventBus, { channels } from "@/eventBus";

export default {
  name: "ProjectDetailsPage",
  mixins: [clientsMixin, workflowMixin, timeMixin],
  components: {
    WrapperPage,
    FormInputGroup,
    WorkflowEditor,
    ContactListEditor,
    MultiSelectDropdown,
    ActionConfirmModal,
    ChooseTimelineStructureModal,
    ChooseProjectDeliverableTypeModal,
    WarningOrErrorModal,
    MultiWorkflowEditor,
  },
  data() {
    return {
      isLoadingDetails: true,
      isLoadingUserOptions: false,
      isSavingDetails: false,
      project: {
        start_date: new Date(),
        days_allowed_for_client_feedback:
          this.$store.getters.defaultDaysAllowedForClientFeedback,
        feedback_reminder_delay: 3,
        send_csat_survey:
          this.$store?.state?.tenantProfile
            ?.csat_mandatory_before_project_complete,
        deliverable_type: "Single",
      },
      client: { contacts: [] },
      contacts: [{}],
      workflow: null,
      templates: [],
      userOptions: [],
      users: [],
      mainDetails: [
        {
          label: "Client",
          name: "client_id",
          type: "select",
          options: [{ text: "Loading clients", value: -1 }],
        },
        { label: "Project Name", name: "name" },
        { label: "Project reference code", name: "reference_code" },
        { label: "Project start date", name: "start_date", type: "date" },
        {
          label: "Default email subject line",
          name: "email_subject_line_default",
        },
      ],
      isProjectArchiveOrUnarchiveClick: false,
      reminderIntervals: [
        {
          text: "Daily",
          value: 1,
        },
        {
          text: "Every 2 days",
          value: 2,
        },
        {
          text: "Every 3 days",
          value: 3,
        },
      ],
      showChooseTimelineStructureModal: false,
      showChooseProjectDeliverableTypeModal: false,
      isLinear: true,
      isShowWarningForLinearSwitch: false,
      linearSwitchWarningModalProps: {
        title: "Error: Transition to Linear Mode Restricted",
        text: "Your project is currently set in Non-Linear mode. At this stage of the timeline, transitioning from Non-Linear to Linear mode is not allowed. To adopt a Linear approach, please start a fresh project in Linear mode.",
      },
      isProjectCancelModalOpen: false,
      projectCancelModalProps: {
        submitText: "Ok",
        title: "Are you sure you want to cancel this project?",
        text: "Please be aware that this action is final and cannot be undone. Once the project is cancelled, it will be permanently closed and cannot be reopened or restored.",
      },
      isShowErrorForAutomatedReminder: false,
      automatedReminderErrorModelProps: {
        submitText: "Add email template",
        title: "Action Required: Email Template Missing",
        text: "It looks like you haven't set an email template for the automated reminders yet. Please add a template in the Email Reminders section to activate this feature.",
      },
      multiDeliverables: [],
      deletedDeliverables: [],
    };
  },
  computed: {
    allUsers() {
      return this.$store.getters.users;
    },
    isShowMultiWorkflowEditor() {
      return (
        (this.multiDeliverables.length || this.isCreating) &&
        !this.isSingleDeliverableType
      );
    },
    isSingleDeliverableType() {
      return this.project.deliverable_type === "Single";
    },
    isProfessionalLevelUser() {
      return isWorkspaceProfessionalLevel(this.$auth.tenant.subscription);
    },
    isProjectCancelled() {
      return Boolean(this.project?.cancelled);
    },
    isArchived() {
      return Boolean(this.project.archived);
    },
    canAutoReminderOn() {
      return this.emailTemplates.some(
        (template) => template.type === "feedback_auto_reminder"
      );
    },
    emailTemplates() {
      return this.$store.getters.emailTemplates;
    },
    getProjectArchiveModalProps() {
      const projectArchiveModalProps = {
        submitClass: "btn-danger",
        submitText: "Ok",
        title: "Are you sure you want to archive this project?",
        text: "This project will be hidden and can be found in the archived projects section.",
        isShowSubmitButton: true,
      };

      if (this.isSubscriptionReadOnly()) {
        projectArchiveModalProps.title = "Read Only";
        projectArchiveModalProps.text = "Your subscription expired";
        projectArchiveModalProps.isShowSubmitButton = false;
      }

      if (this.project?.archived && this.canUnarchiveProjects) {
        projectArchiveModalProps.title =
          "Are you sure you want to unarchive this project?";
        projectArchiveModalProps.text =
          "This project will be visible in the active projects list.";
      } else if (this.project?.archived && !this.canUnarchiveProjects) {
        projectArchiveModalProps.title = "Upgrade your workspace";
        projectArchiveModalProps.text =
          "You reached the archived projects limit for your workspace.";
        projectArchiveModalProps.isShowSubmitButton = false;
      } else if (!this.canArchiveProjects) {
        projectArchiveModalProps.title = "Upgrade your workspace";
        projectArchiveModalProps.text =
          "You reached the projects limit for your workspace.";
        projectArchiveModalProps.isShowSubmitButton = false;
      }

      return projectArchiveModalProps;
    },
    archiveButtonText() {
      return this.project.archived ? "Unarchive Project" : "Archive Project";
    },
    canArchiveProjects() {
      return this.$store.getters.archivedProjectsRemaining !== 0;
    },
    canUnarchiveProjects() {
      return this.$store.getters.activeProjectsRemaining !== 0;
    },
    projectId() {
      return this.$route.params.projectId;
    },
    isCreating() {
      return this.$route.params.projectId === "create";
    },
    contactOptions() {
      const filterdContacts = this.client?.contacts
        // remove contact options that are already added
        ?.filter(
          (contactOption) =>
            !this.contacts.some((c) => +c.id === +contactOption.id)
        )
        // change structure to fit the select component's options data structure
        .map((contactOption) => ({
          text: `${contactOption.firstname} ${
            contactOption.lastname ?? ""
          }`.trim(),
          email: contactOption.email,
          value: contactOption.id,
          $isDisabled: contactOption.$isDisabled === true,
        }))
        .sort((a, b) => b.value - a.value);
      return [...filterdContacts];
    },
    reminderIntervalText() {
      const interval = this.reminderIntervals.find(
        (interval) => interval.value === this.project.reminder_interval
      );

      return interval ? interval.text : "Select Interval";
    },
    activeStages() {
      if (!this.workflow?.tasks || this.workflow?.tasks.length === 0) {
        return null;
      }

      if (this.isLinear) {
        return [this.getCurrentStage()];
      } else {
        return this.workflow?.tasks.filter(
          (task) => task.status == "in_progress"
        );
      }
    },
    canChangeToLinear() {
      return this.checkCanChangeToLinear();
    },
  },
  created() {
    if (!this.$auth.user.isAgent) {
      if (this.isCreating) {
        this.$router.push("/projects/");
      } else {
        this.$router.push("/projects/" + this.projectId + "/timeline");
      }
    } else {
      this.getUserOptions();
    }
  },
  async mounted() {
    if (this.isCreating && !this.$auth.user.create_projects)
      return this.$router.push("/");

    this.checkProjectLimit();

    getWorkflowTemplates()
      .then((data) => (this.templates = data))
      .catch((err) => this.notifyError(err, "Error on loading workflows"));

    if (this.isCreating) {
      this.showChooseProjectDeliverableTypeModal = true;
      if (this.$route?.query?.client) {
        const cliendId = Number(this.$route?.query?.client);
        this.clientSelected(cliendId);
        this.$set(this.project, "client_id", cliendId);
      }
      this.isLoadingDetails = false;
      this.workflow = this.getWorkflowTemplate_Empty();
      return;
    }

    try {
      await this.refresh();
    } catch (err) {
      if (err?.response.status === 404) {
        this.notifyWarn("Project not found");
        this.$router.push("/projects/");
        return;
      }
      this.notifyError(err, "Error on loading project");
    }
  },
  methods: {
    checkProjectLimit() {
      if (this.$store.getters.isLoadingSubscriptions) return;

      if (
        this.isCreating &&
        this.$store.getters.activeProjectsRemaining === 0
      ) {
        this.notifyError("You reached the projects limit for your workspace");
        return this.$router.push("/");
      }
    },
    handleCheckboxClick() {
      if (!this.isProfessionalLevelUser)
        eventBus.$emit(channels.upgradeSubscription, { allowDismissal: true });
      else if (!this.canAutoReminderOn)
        this.isShowErrorForAutomatedReminder = true;
    },
    handleCSATCheckboxClick() {
      eventBus.$emit(channels.upgradeSubscription, { allowDismissal: true });
    },
    navigateToEmailTemplate() {
      this.$router.push("/templates/email-templates#reminders");
    },
    /**
     * Find if any stage is 'in_progress'
     * If none, find the first stage where status = pending or empty
     * @returns A stage object, for the first in_progress stage, or undefined if all stages are complete.
     * @remarks Works only if there is only 1 stage in progress (which is normal for Linear workflows, but not always the case for Non-Linear ones)
     */
    getCurrentStage() {
      if (!this.workflow?.tasks || this.workflow?.tasks.length === 0) {
        return null;
      }
      let stage = this.workflow?.tasks.find(
        (task) => task.status == "in_progress"
      );
      if (!stage) {
        stage = this.workflow?.tasks.find(
          (stage) => !stage.status || stage.status == "pending"
        );
      }
      return stage;
    },
    /**
     * Checks if the workflow can be converted from non-linear to linear.
     */
    checkCanChangeToLinear() {
      if (this.isLinear) return true;

      if (this.activeStages.length > 1) return false;

      let activeStage;
      // If there is just 1 active stage (or none), check that all previous stages are closed and all future stages are pending/unactioned
      if (this.activeStages.length === 0) {
        // In this case, let's fetch the first pending stage and assume that is the active one
        activeStage = this.getCurrentStage();
      } else {
        activeStage = this.activeStages[0];
      }
      const currentIndex = this.workflow?.tasks.findIndex(
        (task) => task.id === activeStage.id
      );
      // Previous ones must be completed
      for (let i = 0; i < currentIndex; i++) {
        const stage = this.workflow?.tasks[i];
        if (!stage.status || stage.status !== "completed") return false;
      }
      // Next ones must be pending
      for (let i = currentIndex + 1; i < this.workflow?.tasks.length; i++) {
        const stage = this.workflow?.tasks[i];
        if (stage.status && stage.status !== "pending") return false;
      }

      return true;
    },
    onIntervalSelected(interval) {
      this.$set(this.project, "reminder_interval", interval?.value);
    },
    toggleTimelineStructure() {
      if (!this.workflow || this.isCreating) {
        this.isLinear = !this.isLinear;
      } else if (this.workflow.is_linear || this.canChangeToLinear) {
        this.isLinear = !this.isLinear;
      } else {
        this.isShowWarningForLinearSwitch = true;
      }
    },
    onTimeLineStructureSelected({ template_id, workflow_title, selected }) {
      this.$refs.multiWorkflowEditor.onDeliverableSubmit({
        template_id,
        workflow_title,
        selected,
        mode: "add",
      });
      this.showChooseTimelineStructureModal = false;
    },
    onProjectDeliverableSelected(selectedDeliverable) {
      this.project.deliverable_type = selectedDeliverable;
      this.showChooseProjectDeliverableTypeModal = false;
    },
    toggleArchive() {
      this.archiveProject(!this.project.archived);
    },
    async cancelProject() {
      try {
        await cancelProjectById(this.projectId, true);
        if (!this.project.archived) {
          await archiveProjectById(this.projectId, true);
        }
        this.notifySuccess("Project Cancelled");
        this.$router.push("/projects/");
      } catch (err) {
        this.notifyError(err, "Error on cancelling project");
      }
    },
    onWfChange({ workflow_id, wf }) {
      const index = this.multiDeliverables.findIndex(
        (deliverable) => deliverable.workflow_id === workflow_id
      );
      if (index !== -1) {
        this.$set(this.multiDeliverables[index], "workflow", {
          ...this.multiDeliverables[index].workflow,
          tasks: wf.tasks,
        });
      }
    },
    toggleMode() {
      if (!this.isCreating) return;
      if (this.project.deliverable_type === "Single") {
        this.project.deliverable_type = "Multi";
      } else {
        this.project.deliverable_type = "Single";
      }
    },
    updateTeamMembers(newTeamMembersList) {
      this.users = newTeamMembersList;
    },
    async refresh() {
      this.isLoadingDetails = true;
      try {
        this.project = await getProjectById(this.projectId);

        if (!this.isProfessionalLevelUser) this.project.auto_reminder = false;

        this.client = await getClientById(this.project.client_id);

        delete this.project.client_name;
        this.contacts = this.project.contacts || [];

        if (
          Array.isArray(this.project.team_members) &&
          this.project.team_members.length > 0
        ) {
          this.users = this.project.team_members.map((tm) => ({
            text: (tm.name + " " + (tm.surname ?? "")).trim(),
            name: tm.name,
            surname: tm.surname,
            value: tm.id,
          }));
          delete this.project.team_members;
        }
        if (this.project.deliverable_type === "Multi") {
          this.fetchAndAssignWorkflowData(this.project.projectWorkflows)
            .then((updatedWorkflowArray) => {
              if (updatedWorkflowArray.length) {
                this.multiDeliverables = [...updatedWorkflowArray];
              }
            })
            .catch((error) => {
              console.error("Error occurred:", error);
            });
        } else {
          getWorkflowById(this.project.workflow_id)
            .then((data) => {
              this.workflow = data;
              this.workflow.tasks = this.workflow.tasks.map((t) => ({
                ...t,
                target_date: DateTime.fromISO(t.target_date)
                  .toUTC()
                  .toFormat("yyyy-MM-dd"),
              }));
              this.isLinear = this.workflow.is_linear;
            })
            .catch((err) => this.notifyError(err, "Error on loading workflow"));
        }
      } finally {
        this.isLoadingDetails = false;
      }
    },
    async archiveProject(shouldArchive) {
      try {
        await archiveProjectById(this.projectId, shouldArchive);

        let successMessage = shouldArchive
          ? "Project Archived"
          : "Project Unarchived";

        this.notifySuccess(successMessage);
        if (!shouldArchive) {
          this.$router.push(`/projects/${this.projectId}/timeline`);
        } else {
          this.$router.push("/projects/");
        }
      } catch (err) {
        this.notifyError(err, "Error on archiving project");
      }
    },
    submitForm() {
      if (this.isSavingDetails) return;

      // We don't call submitProject() directly so that any form validations can be executed
      if (!this.$refs.projectForm.checkValidity()) {
        this.$refs.projectForm.requestSubmit(); // This does not call submitProject(), but just shows the Form Validation errors
        return;
      }
      if (!this.$refs.contactsList.checkValidity()) return;
      let wfValidation = "Editor not loaded properly";
      if (this.isSingleDeliverableType && this.$refs.workflowEditor) {
        wfValidation = this.$refs.workflowEditor.validate();
      } else if (this.$refs.multiWorkflowEditor) {
        wfValidation = this.$refs.multiWorkflowEditor.validate();
      }
      if (wfValidation !== true) {
        this.notifyWarn(wfValidation, "Invalid Workflow editor");
        return;
      }
      this.$refs.projectForm.requestSubmit();
    },
    async associateNewContactsToClient(project) {
      this.contacts
        // get contacts that have no id in this.contacts
        .filter((contact) => !contact.id)
        .forEach((newContact) => {
          // find the matching contact that was added to the db
          const refContact = project.contacts.find(
            (c) =>
              c.firstname === newContact.firstname &&
              c.lastname === newContact.lastname &&
              c.email === newContact.email
          );

          // if the matching contact is found, associate with tbe client
          if (refContact)
            associateContactToClient(refContact.id, project.client_id, "main");
        });
    },
    async deleteProject() {
      if (
        confirm(
          "Are you sure you want to delete project: " + this.project.name + " ?"
        )
      ) {
        try {
          await deleteProjectById(this.project.id);
          this.notifySuccess("Project deleted: " + this.project.name);
          this.$router.push("/projects/");
        } catch (err) {
          this.notifyError(err, "Error: Project Delete");
        }
      }
    },
    async getUserOptions() {
      this.isLoadingUserOptions = true;

      let userOptions = this.allUsers.map((c) => ({
        text: (c.name + " " + (c.surname ?? "")).trim(),
        name: c.name,
        surname: c.surname,
        value: c.id,
        email: c.email,
      }));
      if (userOptions?.length) {
        const activeUser = userOptions.find(
          (option) => option.email === this.$auth.user.email
        );
        if (activeUser) {
          this.users.push(activeUser);
        }
      }

      this.userOptions = [...userOptions];
      this.isLoadingUserOptions = false;
    },
    existingContactAdded(selectedContact, pushed) {
      const contactIndex = this.client.contacts.findIndex(
        (c) => c.id === selectedContact
      );

      if (contactIndex < 0) return;

      this.$set(this.client.contacts[contactIndex], "$isDisabled", !pushed);
    },

    async submitProject() {
      this.isSavingDetails = true;

      await this.prepareContactsAndTeamMembers();
      if (!(await this.validateClient())) return;

      const orderedWorkflow = this.isSingleDeliverableType
        ? this.$refs.workflowEditor.getOrderedWorkflow()
        : this.$refs.multiWorkflowEditor.getOrderedWorkflow();

      if (this.isCreating) {
        if (this.isSingleDeliverableType) {
          await this.handleSingleDeliverableWorkflow(orderedWorkflow);
        } else {
          await this.handleMultipleDeliverableWorkflow(orderedWorkflow);
        }
      } else {
        if (this.isSingleDeliverableType) {
          await this.handleSingleDeliverableUpdate(orderedWorkflow);
        } else {
          await this.handleMultipleDeliverableUpdate(orderedWorkflow);
        }
      }
      // else {
      //   try {
      //     const result = await updateProject(this.project);
      //     this.associateNewContactsToClient(result);
      //     if (result.id) {
      //       this.notifySuccess("Project saved");
      //       this.$router.push("/projects/" + result.id + "/timeline");
      //     } else {
      //       this.notifyError("Project not saved");
      //     }
      //   } catch (err) {
      //     this.isSavingDetails = false;
      //     this.notifyError(err, "Project not saved");
      //   }
      // }

      this.isSavingDetails = false;
    },
    async prepareContactsAndTeamMembers() {
      this.project.contacts = this.contacts.map((obj) => {
        delete obj.inviteStatus;
        return obj;
      });

      this.project.team_members = this.users.map((uo) => {
        return {
          id: uo.value,
          name: uo.name,
          surname: uo.surname,
        };
      });
    },
    async validateClient() {
      if ((this.project?.client_id ?? 0) < 1) {
        this.notifyWarn("Please choose a client");
        this.isSavingDetails = false;
        return false;
      }
      return true;
    },
    async handleSingleDeliverableWorkflow(orderedWorkflow) {
      if (!this.project.workflow_id) {
        if (orderedWorkflow.tasks.length > 1 && this.isLinear) {
          orderedWorkflow.tasks[0].started_on = this.getLuxonDateTime(
            this.project.start_date
          ).toJSDate();
        }
        orderedWorkflow.is_linear = this.isLinear;
        this.workflow = await addWorkflow(orderedWorkflow);
        this.project.workflow_id = this.workflow.id;
      }
      let result = await this.submitProjectAndNotify(this.project);
      if (result.id) {
        this.notifySuccessMSG("Project saved", result.id);
      } else {
        this.notifyError("Project not saved");
      }
    },
    async handleMultipleDeliverableWorkflow(orderedWorkflow) {
      if (!this.project.workflow_id) {
        const promises = orderedWorkflow.map(async (deliverable) => {
          if (deliverable.isLinear && deliverable.workflow?.tasks?.length > 1) {
            deliverable.workflow.tasks[0].started_on = this.getLuxonDateTime(
              this.project.start_date
            ).toJSDate();
          }
          deliverable.workflow.is_linear = deliverable.isLinear;
          deliverable.workflow = await addWorkflow(deliverable.workflow);
          if (!this.project.projectWorkflows?.length)
            this.project.projectWorkflows = [];

          this.project.projectWorkflows.push({
            workflow_id: deliverable.workflow.id,
            workflow_title: deliverable.workflow_title,
          });
        });

        await Promise.all(promises);
      }
      let result = await this.submitProjectAndNotify(this.project);
      if (result.id) {
        this.notifySuccessMSG("Project saved", result.id);
      } else {
        this.notifyError("Project not saved");
      }
    },
    async handleSingleDeliverableUpdate(orderedWorkflow) {
      // In order to update the Project Start Date (which is reflected in the workflow) we need to verify
      // that the dates make sense and the start is not after some other action that was already performed.
      // If the 1st stage (with no tasks) or if the 1st rev in 1st stage is in_progress (in production),
      // then allow any change and update the date,
      // else, if project start date > rev/stage started_on, throw an error and do not allow.
      try {
        const isDataProcessed = this.processWorkflowData(
          orderedWorkflow,
          "Single",
          this.isLinear
        );
        if (!isDataProcessed) {
          this.isSavingDetails = false;
          return;
        }

        this.project.id = this.projectId;
        if (!orderedWorkflow.tasks[0].started_on) {
          orderedWorkflow.tasks[0].started_on = this.getLuxonDateTime(
            this.project.start_date
          ).toISO();
        }

        const result = await updateProject(this.project);
        this.associateNewContactsToClient(result);
        await updateWorkflow(orderedWorkflow, this.workflow.id);
        this.notifySuccess("Project saved");
        this.isSavingDetails = false;
      } catch (err) {
        this.notifyError(err, "Project not saved");
        this.isSavingDetails = false;
      }
    },
    async handleMultipleDeliverableUpdate(orderedWorkflow) {
      this.project.projectWorkflows = this.project.projectWorkflows.filter(
        (w) => !this.deletedDeliverables.includes(w.workflow_id)
      );

      for (const deliverable of orderedWorkflow) {
        this.processWorkflowData(
          deliverable.workflow,
          "Multi",
          deliverable.isLinear
        );
        if (
          deliverable.isLinear &&
          !deliverable.workflow.tasks?.[0].started_on
        ) {
          // Update only if not set already.
          // We might in the future ask the user if they want to modify the Workflow as well along with the Project start date
          deliverable.workflow.tasks[0].started_on = this.getLuxonDateTime(
            this.project.start_date
          ).toISO();
        }
        deliverable.workflow.is_linear = deliverable.isLinear;
        if (deliverable.isNew) {
          deliverable.workflow = await addWorkflow(deliverable.workflow);
        }

        if (!this.project.projectWorkflows?.length)
          this.project.projectWorkflows = [];

        // update this.project.projectWorkflows
        const workflowIdIndex = this.project.projectWorkflows.findIndex(
          (pw) => pw.workflow_id === deliverable.workflow_id
        );

        if (workflowIdIndex !== -1) {
          this.project.projectWorkflows[workflowIdIndex].workflow_title =
            deliverable.workflow_title;
        } else {
          this.project.projectWorkflows.push({
            workflow_id: deliverable.workflow.id,
            workflow_title: deliverable.workflow_title,
          });
        }
      }
      try {
        const result = await updateProject(this.project);
        this.associateNewContactsToClient(result);
        const updateWorkflowPromises = [];
        orderedWorkflow.forEach((workflow) => {
          const updatePromise = updateWorkflow(
            workflow.workflow,
            workflow.workflow_id
          );
          updateWorkflowPromises.push(updatePromise);
        });
        await Promise.all(updateWorkflowPromises);

        this.notifySuccess("Project saved");
        this.isSavingDetails = false;
      } catch (err) {
        this.notifyError(err, "Project not saved");
        this.isSavingDetails = false;
      }
    },
    processWorkflowData(orderedWorkflow, deliverableType, isLinear) {
      if (deliverableType === "Single") {
        orderedWorkflow.is_linear = isLinear;
      }
      if (orderedWorkflow.tasks.length > 0) {
        let firstStageOrRev = orderedWorkflow.tasks[0]; // First stage
        let startedOn;

        if (orderedWorkflow.is_linear) {
          if (firstStageOrRev.tasks.length > 0) {
            firstStageOrRev = firstStageOrRev.tasks[0]; // First Rev if any
          }
          if (
            firstStageOrRev.status &&
            firstStageOrRev.status !== "in_progress"
          ) {
            startedOn =
              firstStageOrRev.started_on ??
              firstStageOrRev.approved_on ??
              firstStageOrRev.sent_on;
          }
        } else {
          for (const stage of orderedWorkflow.tasks) {
            if (stage.tasks.length > 0) {
              firstStageOrRev = stage.tasks[0];
              break;
            }
          }
        }

        if (startedOn) {
          const startedOnUtc = this.getLuxonDateTime(startedOn);
          const projStartDateUtc = this.getLuxonDateTime(
            this.project.start_date
          );
          if (startedOnUtc < projStartDateUtc) {
            this.notifyWarn(
              "Your workflow was actioned before the Project Start date.\nPlease revise the start date to be before: " +
                this.toWorkspaceDateTimeFormat(startedOnUtc),
              "Project Start Date",
              5000
            );
            return false;
          }
        }
      }

      return true;
    },
    async submitProjectAndNotify(project) {
      try {
        const result = await addProject(project);
        this.associateNewContactsToClient(result);
        return result;
      } catch (err) {
        this.isSavingDetails = false;
        this.notifyError(err, "Project not saved");
        return {};
      }
    },
    notifySuccessMSG(message, projectId) {
      this.notifySuccess(message);
      if (projectId) {
        this.$router.push("/projects/" + projectId + "/timeline");
      }
    },
  },

  watch: {
    "$store.getters.isLoadingSubscriptions": function () {
      this.checkProjectLimit();
    },
    allUsers: function () {
      this.getUserOptions();
    },
    "project.reference_code": function (new_val) {
      let subjectLineValue = new_val;

      if (this.project.name) {
        if (new_val.length != 0) {
          subjectLineValue = `${new_val} - ${this.project.name}`;
        } else {
          subjectLineValue = this.project.name;
        }
      }

      this.$set(this.project, "email_subject_line_default", subjectLineValue);
    },

    "project.name": function (new_val) {
      let subjectLineValue = new_val;

      if (this.project.reference_code) {
        if (new_val.length != 0) {
          subjectLineValue = `${this.project.reference_code} - ${new_val}`;
        } else {
          subjectLineValue = this.project.reference_code;
        }
      }

      this.$set(this.project, "email_subject_line_default", subjectLineValue);
    },

    "project.email_subject_line_default": function (
      email_subject_line_default
    ) {
      this.$set(
        this.project,
        "email_subject_line_default",
        email_subject_line_default || ""
      );
    },
  },
};
</script>
<style scoped>
.btn-round {
  background-color: #1773f6;
}
.menuLink {
  padding: 0.4rem 0.4rem;
  display: inline-block;
}
</style>
