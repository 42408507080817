<template>
  <li class="timeline-item">
    <div class="timeline-marker-completed">
      <div class="icon text-cyan text-large">
        <i class="fa fa-check-circle"></i>
      </div>
    </div>
    <div class="timeline-content">
      <h3 class="timeline-title">
        <div>
          {{ model.title }}
          <stage-menu
            @clicked="onMenuClick"
            :items="
              makeMenu([
                model.type === 'Stage' && isAgency && menuItems.AddNote,
                !root.is_linear && isAgency && menuItems.reOpenStage,
                root.is_linear &&
                  isAgency &&
                  isLastStageForLinearProject &&
                  menuItems.reOpenStage,
              ])
            "
          />
          <span v-if="model.type === 'Stage' && isNotesAvailable">
            <div class="d-inline-block pl-1">
              <a
                href="#"
                data-toggle="modal"
                @click="onNotesIconClicked(model)"
                :style="{ color: '#bfbfbf' }"
              >
                <img
                  src="/assets/icons/note.svg"
                  height="20px"
                  width="20px"
                  style="margin-top: -1px"
                />
              </a>
            </div>
          </span>
        </div>
        <button
          v-if="isExpandable"
          class="btnExpand"
          @click="isExpanded = !isExpanded"
        >
          <i v-if="isExpanded" class="fa fa-angle-up"></i>
          <i v-else class="fa fa-angle-down"></i>
        </button>
      </h3>

      <h6 v-if="this.model.type === 'Revision'" class="small text-black-50">
        {{ subtitleText() }}
      </h6>
      <stage-subtitle-text :stage="model" :workflow="root" />
    </div>
    <SendMessageModal
      v-if="isSendMessageModalOpen"
      :model="model"
      :project="project"
      :workflow="root"
      @send="sendMessage"
      @close="isSendMessageModalOpen = false"
    />
    <div v-if="isExpanded" class="col-lg-12">
      <ul class="timeline">
        <li v-for="log in logs" :key="log.key" class="timeline-item">
          <div :class="log.marker"></div>
          <div class="timeline-content">
            <h3 v-if="log.title" class="timeline-title">
              {{ log.title }}
            </h3>

            <h6 v-if="log.subtitle || log.link" class="small text-black-50">
              <template v-if="log.subtitle && log.qc_checked_by">
                QC
                <span
                  ><i class="fa fa-check-circle-o" style="color: green"></i>
                  | </span
                >{{ log.subtitle }}</template
              >
              <template v-else-if="log.subtitle">{{ log.subtitle }}</template>
              <template v-else-if="log.qc_checked_by">
                <span> | </span> {{ log.qc_checked_by }}</template
              >
              <span
                v-if="log.link && !(files?.[log?.revisionId] ?? [])?.length"
              >
                <span v-if="log.subtitle"> | </span>
                <a
                  v-if="log.link"
                  class="shared-link text-black-50"
                  :href="log.link"
                  target="_blank"
                >
                  Shared link
                </a>
              </span>
            </h6>

            <p
              v-for="file in files?.[log?.revisionId] ?? []"
              :key="file.name"
              class="mb-1"
            >
              <a :href="file.url" target="_blank">
                {{ file.name }}
              </a>
            </p>
          </div>
        </li>
      </ul>
    </div>
    <notes-model
      v-if="isShowNotesModel"
      @close="isShowNotesModel = false"
      :stageNotes="selectedStageNotes"
    ></notes-model>
    <NotesEditorModel
      v-if="isShowNotesEditorModel"
      title="Add note"
      :timeline="parent"
      action="add"
      :selectedStage="model"
      @close="isShowNotesEditorModel = false"
      @save="onSaveNote"
    />
    <UndoLastActionConfirmModal
      :title="'Re-open stage'"
      :isReopeningStage="true"
      v-if="isUndoLastActionConfirmModalOpen"
      @submit="undoLastAction"
      @close="isUndoLastActionConfirmModalOpen = false"
      :disableSubmitButton="isGoingBackStage"
    />
  </li>
</template>

<script>
import timeMixin from "../../../mixins/time";
import workflowMixin from "../../../mixins/workflow";
import textEditorCompilerMixin from "../../../mixins/textEditorCompiler";
import StageSubtitleText from "./StageSubtitleText.vue";
import SendMessageModal from "@/components/ui/Modals/SendMessageModal.vue";
import StageMenu, {
  makeMenu,
  menuItems,
} from "@/components/ui/Timeline/StageMenu.vue";
import NotesEditorModel from "@/components/ui/Notes/NotesEditorModel.vue";
import { addNotes } from "@/apis/notes";
import NotesModel from "../Notes/NotesModel.vue";
import notesFormatterMixin from "../../../mixins/notesFormatter";
import {
  getRevisionFiles,
  undoLastNonLinearTimelineAction,
  undoLastTimelineAction,
} from "@/apis/projects";
import UndoLastActionConfirmModal from "@/components/ui/Modals/UndoLastActionConfirmModal.vue";
import eventBus, { channels } from "@/eventBus";

export default {
  components: {
    NotesEditorModel,
    StageMenu,
    StageSubtitleText,
    SendMessageModal,
    NotesModel,
    UndoLastActionConfirmModal,
  },
  name: "ClosedTimelineListItem",
  mixins: [
    timeMixin,
    workflowMixin,
    textEditorCompilerMixin,
    notesFormatterMixin,
  ],
  props: {
    model: Object, // Task (Stage or Revision)
    parent: Object, // Stage or Workflow
    root: Object, // Main workflow
    project: Object,
    stage: {},
    events: {
      type: Array,
      default: () => [],
    },
    notes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      files: {},
      isExpanded: false,
      revisionIdForReminder: "",
      isSendMessageModalOpen: false,
      isGoingBackStage: false,
      menuItems,
      isShowNotesEditorModel: false,
      isShowNotesModel: false,
      isUndoLastActionConfirmModalOpen: false,
    };
  },
  computed: {
    users() {
      return this.$store.getters.users.map((u) => ({
        id: u.id,
        name: (u.name + " " + (u.surname ?? "")).trim(),
      }));
    },
    isNotesAvailable() {
      const avlNotes = this.notes.filter(
        (note) => note.stage_id === this.model?.id
      );
      return avlNotes.length;
    },
    isExpandable() {
      return this.logs.length;
    },
    isAgency() {
      return this.$auth.user.isAgent;
    },
    isLastStageForLinearProject() {
      return this.isLastStage(this.stage, this.parent);
    },
    logs() {
      const list = [];

      this.model.tasks.forEach((step) => {
        if (!step.status) return;

        if (!this.files?.[step.id]) this.retrieveRevisionFiles(step.id);

        if (step.title === "First Preview" && step.sent_on) {
          const sentEvent = this.events.find((event) => {
            if (event.category === "Send Preview") {
              const eventData = JSON.parse(event.event);
              return eventData.revisionId === step.id;
            }

            return false;
          });

          list.push({
            category: "event",
            title: "First preview shared",
            subtitle: `Shared ${this.toWorkspaceDateTimeFormat(
              this.getLuxonDateTime(step.sent_on, true)
            )} ${
              sentEvent?.user_id ? this.actionByUser(sentEvent.user_id) : ""
            }`,
            link: this.makeLink(step.data?.review_link),
            date: this.getLuxonDateTime(step.sent_on, true),
            qc_checked_by:
              this.model?.data?.qcChecklistId && sentEvent?.user_id
                ? this.actionByUser(sentEvent.user_id, "QC")
                : undefined,
            marker: "timeline-marker",
            revisionId: step.id,
          });
        } else {
          let subtitle = null;
          let qc_checked_by = undefined;

          if (step.sent_on) {
            const sentEvent = this.events.find((event) => {
              if (event.category === "Send Preview") {
                const eventData = JSON.parse(event.event);
                return eventData.revisionId === step.id;
              }

              return false;
            });

            subtitle = `Shared ${this.toWorkspaceDateTimeFormat(
              this.getLuxonDateTime(step.started_on, true)
            )} ${
              sentEvent?.user_id ? this.actionByUser(sentEvent.user_id) : ""
            }`;
            qc_checked_by =
              this.model?.data?.qcChecklistId && sentEvent?.user_id
                ? this.actionByUser(sentEvent.user_id, "QC")
                : undefined;
          }

          list.push({
            category: "step",
            title: step.title,
            subtitle,
            link: this.makeLink(step.data?.review_link),
            date: this.getLuxonDateTime(step.started_on, true),
            qc_checked_by,
            marker: "timeline-marker",
            revisionId: step.id,
          });
        }

        if (step.status === "rejected" && step.completed_on) {
          const latestRejectEventObject = this.getLatestEventObject(
            this.events,
            "Revision Rejected",
            step.id
          );
          let eventData;
          if (latestRejectEventObject) {
            eventData = JSON.parse(latestRejectEventObject.event);
          }
          const subtitle = `Requested ${this.toWorkspaceDateTimeFormat(
            this.getLuxonDateTime(step.completed_on, true)
          )} ${this.getName(eventData)}`;
          list.push({
            category: "event",
            title: "Revisions requested",
            subtitle,
            link: null,
            date: this.getLuxonDateTime(step.completed_on, true),
            marker: "timeline-marker",
          });
        } else if (step.status === "approved" && step.completed_on) {
          const latestApprovedEventObject = this.getLatestEventObject(
            this.events,
            "Stage Approved",
            step.id,
            this.model.id
          );
          let eventData;
          if (latestApprovedEventObject) {
            eventData = JSON.parse(latestApprovedEventObject.event);
          }
          const subtitle = `Approved ${this.toWorkspaceDateTimeFormat(
            this.getLuxonDateTime(step.completed_on, true)
          )} ${this.getName(eventData)}`;
          list.push({
            category: "event",
            title: "Approved",
            subtitle,
            link: this.makeLink(step.data?.review_link),
            date: this.getLuxonDateTime(step.completed_on, true),
            marker: "timeline-marker approved",
          });
        }
      });

      this.events.forEach((event) => {
        if (
          event.category === "Send Preview" ||
          event.category === "Revision Rejected" ||
          event.category === "Stage Approved" ||
          event.category === "Revision Approved"
        )
          return;

        const log = {
          category: "event",
          title: event.category,
          subtitle: null,
          link: null,
          date: this.getLuxonDateTime(event.event_time, true),
          marker: "timeline-marker",
          revisionId: JSON.parse(event.event)?.revisionId,
        };

        if (event.category === "Revision Reminder") {
          log.title = "Reminder sent";
          log.subtitle = `Sent ${this.toWorkspaceDateTimeFormat(log.date)} ${
            event?.user_id ? this.actionByUser(event.user_id) : ""
          }`;
        }

        list.push(log);
      });

      return list
        .sort((a, b) => a.date.toMillis() - b.date.toMillis())
        .map((log) => ({
          ...log,
          key: `${log.category}-${log.title}-${log.date.toFormat("x")}`,
        }));
    },
  },
  methods: {
    retrieveRevisionFiles(revisionId) {
      getRevisionFiles(this.project.id, this.root.id, revisionId).then(
        (res) => {
          this.$set(
            this.files,
            revisionId,
            res.map((f) => {
              const storageEndpoint = process.env.VUE_APP_AZ_STORAGE_ENDPOINT;
              const url = `${storageEndpoint}${
                storageEndpoint.at(-1) !== "/" ? "/" : ""
              }documents/${f.path}`;
              const filename = url.substring(url.lastIndexOf("/") + 1);

              let thumbnail = null;
              const type = `.${filename.split(".")[1]?.toLowerCase()}`;
              if (
                [
                  ".jpg",
                  ".png",
                  ".svg",
                  ".gif",
                  ".jpeg",
                  ".bmp",
                  ".webm",
                  ".webp",
                  ".ogg",
                  ".mp4",
                  ".avi",
                  ".mkv",
                  ".wmv",
                  ".mov",
                ].includes(type)
              ) {
                thumbnail = url.replace(filename, `thumbnails/${filename}`);
              }

              return {
                url,
                size: f.size,
                created_on: f.created_on,
                thumbnail,
                name: filename,
                type,
              };
            })
          );
        }
      );
    },
    makeMenu,
    makeLink(link) {
      if (link && !link.includes("://")) return `https://${link}`;

      return link;
    },
    onNotesIconClicked(modal) {
      this.selectedStageNotes = this.notes.filter(
        (note) => note.stage_id === modal.id
      );
      this.isShowNotesModel = true;
    },
    async undoLastAction(reason) {
      this.isGoingBackStage = true;

      const stageId = this.model.id;
      const workflowId = this.root.id;
      const undoAction = this.root.is_linear
        ? undoLastTimelineAction(this.project.id, workflowId, reason)
        : undoLastNonLinearTimelineAction(
            this.project.id,
            workflowId,
            stageId,
            reason
          );

      undoAction
        .then(() => {
          eventBus.$emit(channels.refreshProject);
          this.isUndoLastActionConfirmModalOpen = false;
        })
        .catch(() => {
          console.log("Error on undo last action");
        })
        .finally(() => {
          this.isGoingBackStage = false;
        });
    },
    async onMenuClick(item) {
      if (item === menuItems.SendMessage) this.isSendMessageModalOpen = true;
      else if (item === menuItems.AddNote) {
        this.isShowNotesEditorModel = true;
      } else if (item === menuItems.reOpenStage)
        this.isUndoLastActionConfirmModalOpen = true;
    },
    timeElapsed(since, pastSuffix = " overdue") {
      return (
        this.timeElapsedBetween(since, new Date(), true, pastSuffix) + " ago"
      );
    },
    capitalize(text) {
      return text[0].toUpperCase() + text.substring(1);
    },
    subtitleText() {
      if (this.model.completed_on) {
        return (
          this.capitalize(this.model.status) +
          " " +
          this.timeElapsed(this.model.completed_on)
        );
      }
      if (this.model.sent_on) {
        return "Shared " + this.timeElapsed(this.model.sent_on);
      }
      if (this.model.started_on) {
        return "Started " + this.timeElapsed(this.model.started_on, "");
      }
      if (this.model.requested_on) {
        return "Requested " + this.timeElapsed(this.model.requested_on);
      }
    },
    sendMessage(sendMsgPayload) {
      this.$emit("stage_send_message_click", sendMsgPayload);
      this.isSendMessageModalOpen = false;
    },
    actionByUser(userId, prefix = "by") {
      const user = this.users.find((u) => u.id === userId);
      if (user) return `${prefix} ${user.name}`;

      return "";
    },
    getName(eventData, prefix = "by") {
      return eventData ? `${prefix} ${eventData.user_name}` : "";
    },
    getLatestEventObject(events, category, revisionId, stageId) {
      let filteredEvents;
      if (category === "Stage Approved") {
        filteredEvents = events.filter(
          (item) => JSON.parse(item.event).stageId === stageId
        );
      } else {
        filteredEvents = events.filter(
          (item) =>
            JSON.parse(item.event).revisionId === revisionId &&
            item.category === category
        );
      }

      return filteredEvents.length > 0
        ? filteredEvents.reduce((latest, current) =>
            new Date(current.event_time) > new Date(latest.event_time)
              ? current
              : latest
          )
        : null;
    },
    async onSaveNote({ payload, action }) {
      if (action === "add") {
        try {
          const newNote = await addNotes(this.project.id, payload);
          this.formatNotes(newNote, this.parent);
          this.$emit("add", newNote);
          this.notifySuccess("Note added");
        } catch (error) {
          this.notifyError(error, "Error adding note");
        }
      }
      this.isShowNotesEditorModel = false;
    },
  },
};
</script>

<style scoped>
.btnExpand {
  border: none;
  background-color: transparent;
  outline: none;
}

.btnExpand i {
  color: #c5c5c6;
  font-size: 1.5rem;
}

.timeline-title {
  max-width: 750px;
  display: flex;
  justify-content: space-between;
}

.timeline-marker::after {
  width: 1px !important;
}
</style>

<style>
.timeline .timeline-marker.approved::before {
  background: #34ba69;
}

.timeline .timeline-item:not(.period):hover .timeline-marker.approved:before {
  border-color: #34ba69;
}

.shared-link {
  text-decoration: underline;
}
</style>
